<template lang="pug">
    div.select-language
        div.img-container
            img.logo(src="/img/logo-header.png")
        div.language-list-block(v-if="languageList")
            div.language-row(:class="{'selected': language.code == userLanguage }" v-for="language in languageList" @click="updateUserLanguage(language)")
                img(:src="language.icon")
                p.subtitle-averta-regular-18.label {{language.label}}
</template>

<script>

export default {
    name: "select-language",
    computed: {
        languageList() {
            let toReturn = []
            let languageList = this.$store.getters['language/getLanguageList'];
            if (languageList) {
                Object.keys(languageList).forEach((key) => {
                    let language = languageList[key]
                    toReturn.push(language)
                });
            }
            return toReturn
        },
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    },
    methods: {
        updateUserLanguage(language) {
            this.$store.dispatch('language/updateUserLanguage', language.code).then(response => {
                this.$router.push('/welcome');
            })
        }
    },
    components: {
        Modal: () => import ("skillbase-component/src/components/common/Modal.vue")
    },
    mounted() {
        this.$store.dispatch('language/fetchLanguageList')
    }
}
</script>

<style scoped lang="scss">
.select-language {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: url("/img/welcomepage.png");
    background-size: cover;
    height: 100vh !important;
    overflow: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    position: relative;

    .logo {
        position: absolute;
        bottom: calc(10vh + 50px);
        left: 110px;
        width: 180px;
        filter: var(--svg-filter-white-color);
    }

    .language-list-block {
        width: 270px;
        background: white;
        border-radius: 5px;
        padding: 27px 0px;
        .language-row {
            display: flex;
            align-items: center;
            padding: 13px 28px;
            margin: 20px auto;
            cursor: pointer;
            width: 140px;
            .label {
                margin-left: 20px;
            }
            &.selected, &:hover {
                box-shadow: 0px 0px 16px var(--shadow-color);
                border-radius: 4px;
            }
            img {
                height: 18px;
                width: 18px;
            }
        }
    }
}
</style>